import heic2any from 'heic2any'
import Compressor from 'compressorjs'

export default class ImageUtil {
  /**
   * @desc HEIC 轉 JPG
   * @param {binary} blob
   * @return {binary}
   */
  static async heic2jpg(blob: any) {
    const blobImgData = await heic2any({
      blob: blob,
      toType: "image/jpeg",
      quality: 0.5
    });
    return blobImgData
  }

  /**
   * @desc 壓縮圖片檔案
   * @param {binary} blob
   * @return {binary}
   */
  static compressImage(blob: any, quality=0.6) {
    return new Promise((resolve, reject) => {
      new Compressor(blob, {
        quality: quality,
        success: resolve,
        error: reject,
      });
    }).catch((err) => {
      console.error('Compress error: ' + err.message);
    });
  }

  /**
   * @desc 壓縮圖片檔案
   * @param {binary} file
   * @return {number} 圖片大小(mb)
   */
  static getFileSize(file: any) {
    const fileMB = file.size / 1024 / 1024;
    return fileMB
  }

  /**
   * @desc 壓縮圖片檔案
   * @param {binary} file
   * @return {boolean}
   */
  static validFileSize(file: any) {
    const fileMB = file.size / 1024 / 1024;
    if (fileMB > 5) {
      return false
    }
    return true
  }
}