<template>
<div class="liff-me-setting-page">
  <template v-if="!loading">
    <a-spin :spinning="updating" tip="努力更新資料中">
      <div class="page__header">
        <a-button type="text" @click="$router.push({ name: 'LiffMeIndex' })">
          <template #icon><LeftOutlined /></template>
          回到主頁
        </a-button>
      </div>

      <div class="page__body">
        <div align="center">
          <div class="body-avatar-wrapper">
            <template v-if="Object.keys(avatarBufferImage).length">
              <a-avatar
                class="body-avatar"
                :src="avatarBufferImage.src"
              />
            </template>
            <template v-else>
              <a-avatar
                class="body-avatar"
                :src="userData.info.avatar!=null ? userData.info.avatar.uri : require('@/assets/icons/avatar.png')"
              />
            </template>
            
            <input
              class="avatar-input"
              type="file" ref="file" data-target="file-uploader" accept="image/*"
              @change="handleImageChange"
            />
          </div>

          <div class="body-name">
            {{ userData.info.name }}
          </div>
        </div>
      </div>

      <div class="page__form">
        <a-form
          :model="formState"
          layout="vertical"
          autocomplete="off"
        >
          <a-row>
            <a-col :span="24">
              <a-form-item
                label="會員編號"
                name="member_id"
              >
                <a-input v-model:value="formState.member_id" disabled size="large" />
              </a-form-item>
            </a-col>
          </a-row>
          
          <a-row>
            <a-col :span="24">
              <a-form-item
                label="姓名"
                name="name"
                :rules="[{ required: true, message: '請輸入姓名' }]"
              >
                <a-input v-model:value="formState.name" size="large" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="24">
              <a-form-item
                label="性別"
                name="gender"
                :rules="[{ required: true, message: '請選擇性別' }]"
              >
                <a-select
                  v-model:value="formState.gender"
                  :options="genderOptions"
                  size="large"
                />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="24">
              <a-form-item
                label="生日"
                name="birthdate"
                :rules="[{ required: true, message: '請輸入生日' }]"
              >
                <a-date-picker
                  v-model:value="formState.birthdate"
                  :show-time="{ format: 'YYYY-MM-DD' }"
                  format="YYYY-MM-DD"
                  size="large"
                  :style="{ width: '100%' }"
                />
              </a-form-item>
            </a-col>
          </a-row>
            
          <a-row>
            <a-col :span="24">
              <a-form-item
                label="聯絡電話"
                name="phone"
                :rules="[{ required: true, message: '請輸入聯絡電話' }]"
              >
                <a-input v-model:value="formState.phone" size="large" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="24">
              <a-form-item
                label="LINE ID"
                name="line_friend_id"
                :rules="[{ required: true, message: '請輸入會員的 LINE Id' }]"
              >
                <a-input v-model:value="formState.line_friend_id" size="large" />
              </a-form-item>
            </a-col>
          </a-row>

          <a-row>
            <a-col :span="24">
              <a-form-item
                label="聯絡地址"
                name="address"
                :rules="[{ required: true, message: '請輸入聯絡地址' }]"
              >
                <a-input v-model:value="formState.address" size="large" />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>

        <a-button
          @click="updateUserInfo"
          class="form-button"
          type="primary"
        >更新資訊</a-button>
      </div>
    </a-spin>
  </template>

  <template v-else>
    <div :style="{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '70vh' }">
      <a-spin
        tip="載入中"
      />
    </div>
  </template>
</div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { LeftOutlined } from '@ant-design/icons-vue'
import { message, Empty } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import liff from '@line/liff/dist/lib'
import moment from 'moment'
import { LineChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import dayjs from 'dayjs'
import api from '@/apis'
import ImageUtil from '@/utils/ImageUtil'
import DateUtil from '@/utils/DateUtil'
import TrainingIcon from '/src/components/ui/TrainingIcon'

export default defineComponent({
  components: {
    LeftOutlined
  },
  setup() {
    /* Liff */
    const context = ref({});
    const initLiff = (async () => {
      try {
        await liff.init({ liffId: process.env.VUE_APP_LIFF_ME });
        context.value = liff.getContext();
        console.log(context.value);
      } catch (err) {
        console.log(err.code, err.message);
      }
    });

    /* Loading */
    const loading = ref(true);
    const updating = ref(false);

    /* Data */
    const userData = ref({});

    /* Form State */
    const formState = ref({});

    /* Image Function */
    const avatarBufferImage = ref({});
    const handleImageChange = (async (event) => {
      let blobImgData = event.target.files[0];
      console.log(blobImgData)

      // compress image
      if (ImageUtil.getFileSize(blobImgData) > 5) {
        blobImgData = await ImageUtil.compressImage(blobImgData);
      }

      // convert heic2jpg or compress image
      if (blobImgData.type === 'image/heic') {
        blobImgData = await ImageUtil.heic2jpg(blobImgData);
      } else {
        blobImgData = await ImageUtil.compressImage(blobImgData);
      }

      if (!ImageUtil.validFileSize(blobImgData)) {
        message.error('圖片檔案必須小於 5 MB');
        return
      }

      const reader = new FileReader();
      reader.readAsDataURL(blobImgData);
      reader.onload = function(e) {
        const imgData = e.target.result;
        avatarBufferImage.value = {
          src: imgData,
          data: blobImgData
        }
        console.log(avatarBufferImage.value)
      }
    });

    /* Api */
    const getUserDataByLineId = (async () => {
      let line_id;
      if (process.env.VUE_APP_ENV == 'production') {
        line_id = context.value.userId;
      } else if (process.env.VUE_APP_ENV == 'development') {
        line_id = process.env.VUE_APP_DEV_LINE_ID;
      }

      const response = await api.v1.line.parserLineId({
        line_id: line_id
      });
      console.log(response.data.result);
      userData.value = { ...response.data.result };

      formState.value = {
        member_id: response.data.result.member_id,
        name: response.data.result.info.name,
        avatar: response.data.result.info.avatar,
        gender: response.data.result.info.gender,
        birthdate: response.data.result.info.birthdate ? dayjs(response.data.result.info.birthdate, 'YYYY-MM-DD'): '',
        phone: response.data.result.info.phone,
        line_friend_id: response.data.result.info.line_friend_id,
        address: response.data.result.info.address
      };
    });

    const uploadFile = (async (file) => {
      let fileSrc = '';
      let err = undefined;

      let formData = new FormData();
      formData.append('file', file, file.name);
      
      try {
        const response = await api.v1.file.insertOne(formData);
        fileSrc = response.data.result.file_src;
      } catch (error) {
        console.log(error);
        err = '檔案上傳失敗，請稍候再試';
      }

      return { fileSrc, err }
    });

    const deleteFile = (async (uri) => {
      const codeSplitList = uri.split('/');
      const key = codeSplitList[codeSplitList.length-1];
      await api.v1.file.deleteOne({ key: key });
    });

    const updateUserInfo = (async () => {
      updating.value = true;

      // if upload new avatar, replace the old one
      if (Object.keys(avatarBufferImage.value).length) {
        const { fileSrc, err } = await uploadFile(avatarBufferImage.value.data);
        if (err) {
          message.error('上傳大頭貼發生錯誤，請稍後再試');
          updating.value = false;
          return
        }
        
        if (userData.value.info.avatar) {
          await deleteFile(userData.value.info.avatar.uri);
        }

        formState.value.avatar = {
          name: avatarBufferImage.value.data.name,
          uri: fileSrc,
          size: avatarBufferImage.value.data.size
        };
        console.log(formState.value);
        avatarBufferImage.value = {};
      }

      // set updatedData & birthdate format
      const updatedData = { ...formState.value };
      if (updatedData.birthdate) {
        updatedData.birthdate = dayjs(updatedData.birthdate).format('YYYY-MM-DD');
      }

      try {
        delete updatedData.member_id
        await api.v1.user.updateOne(userData.value.uid, updatedData);
        message.success('更新會員資料成功');
      } catch (error) {
        message.error('更新會員資料失敗，請稍後再試');
      } finally {
        getUserDataByLineId();
        updating.value = false;
      }
    });

    /* Mounted */
    onMounted(async () => {
      await initLiff();
      await getUserDataByLineId();

      loading.value = false;
    });

    /* Options */
    const genderOptions = ref([{
      value: 'male',
      label: '先生'
    }, {
      value: 'female',
      label: '女士'
    }]);

    return {
      /* Loading */
      loading,
      updating,

      /* Data */
      userData,

      /* Form State */
      formState,

      /* Image Function */
      avatarBufferImage,
      handleImageChange,

      /* Api */
      updateUserInfo,

      /* Options */
      genderOptions
    }
  },
  methods: {

  }
})
</script>

<style lang="scss">
.liff-me-setting-page {
  min-height: 100vh;
  padding: 16px 8px;

  .page__header {

  }

  .page__body {
    padding: 16px 0;

    .body-avatar-wrapper {
      display: inline-block;
      width: 90px;
      height: 90px;
      position: relative;
      margin-bottom: 4px;

      .body-avatar {
        position: absolute;
        left: 0;
        top: 0;
        width: 90px;
        height: 90px;
        
        img {
          width: 90px;
          height: 90px;
        }
      }

      .avatar-input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: pointer !important;
        z-index: 1;
      }
    }

    .body-name {
      font-size: 1.8rem;
      font-weight: 500;
      letter-spacing: 0.05rem;
    }
  }

  .page__form {
    padding: 12px 24px 28px 24px;

    .form-button {
      width: 100%;
      height: 36px;
      font-weight: 500;
      font-size: .9rem;
      letter-spacing: 0.05rem;
    }
  }
}
</style>