<template>
<div class="ui-training-icon">
  <template v-if="actionName && haveIcon(actionName)">
    <a-image
      :src="require(`/src/assets/icons/training/kettlebell/${actionName}.png`)"
      :height="`${height}px`"
      :width="`${height}px`"
    />
  </template>
  <template v-else>
    <a-image
      :src="require('/src/assets/icons/training/default.png')"
      height="45px"
      width="50px"
    />
  </template>
</div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    actionName: {
      type: String
    },
    height: {
      type: Number,
      default: 60
    }
  },
  components: {},
  setup() {
    const actionNameIcons = ref(['硬舉', '深蹲', '盪鈴']);
    const haveIcon = ((actionName) => {
      if (actionNameIcons.value.includes(actionName)) return true
      else false
    });

    return {
      actionNameIcons,
      haveIcon
    }
  },
  methods: {

  }
})
</script>