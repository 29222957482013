/**
 * Functions of processing date format
 * Created by Ching-Hsuan Su on 2020/12/09.
 */

import moment from 'moment'
// import tz from 'moment-timezone';

export default class DateUtil {
  /**
   * @desc 將日期格式化 e.g. 2020-02-07
   * @param oriDate {string}
   * @return {string} 格式化後的日期格式
   */
  static formatDate(oriDate: string) {
    // let timezone = "GMT";
    return moment(oriDate).format('YYYY-MM-DD')
  }

  /**
   * @desc 將日期格式化 e.g. 2020-02-07 21:01:30
   * @param oriDate
   * @return {string} 格式化後的日期格式
   */
  static formatDatetime(oriDate: string) {
    // let timezone = "GMT";
    return moment(oriDate).format('YYYY-MM-DD HH:mm')
  }

  /**
   * @desc 日期與今天的天數差
   * @param date_1
   * @param date_2 options
   * @return {int} 天數差
   */
  static diff(date_1: string, date_2: string|null = null) {
    let diffDay = null;

    if (date_2) {
      diffDay = moment(date_1).diff(moment(date_2), 'day');
    } else {
      diffDay = moment(date_1).diff(moment(), 'day');
    }
    return diffDay;
  }

  /**
   * @desc 將前後兩日期與今天日期轉換成百分制進度值
   * @param sd {string} 起始日期
   * @param ed {string} 結束日期
   * @return {int} 天數差
   */
  static progressValue(sd: string, ed: string) {
    let today = moment();
    let startDate = moment(sd);
    let endDate = moment(ed);
    
    let diffStartEndDay = endDate.diff(startDate, 'day') + 1;
    let diffTodayStartDay = today.diff(startDate, 'day') + 1;
    
    return Math.round(diffTodayStartDay/diffStartEndDay*100)
  }

  /**
   * @desc 當前日期
   * @return {str} 日期
   */
  static currentDate() {    
    return moment(new Date()).format("YYYY-MM-DD")
  }

  /**
   * @desc 拆解年、月、日
   * @return {str} 日期 YYYY-MM-DD
   */
  static formalizeDate(date: string) {
    const dateMoment = moment(date, "YYYY-MM-DD");
    
    const result = {
      year: dateMoment.year(),
      month: dateMoment.month()+1,
      date: dateMoment.date()
    }
    return result
  }

  /**
   * @desc 增加 day 天後的日期
   * @param {string} date 日期
   * @param {number} day 增加的天數
   * @return {string} 日期 YYYY-MM-DD
   */
  static addDay(date: string, day: number) {
    let dateMoment = moment(date, "YYYY-MM-DD");
    dateMoment = dateMoment.add(day, 'days');
    
    return dateMoment.format('YYYY-MM-DD')
  }

  /**
   * @desc 轉換數字day成為中文day e.g. 1->一、7->日
   * @return {str} 中文day
   */
  static toChineseDay(day: string) {
    const dayChList = ['一', '二', '三', '四', '五', '六', '日'];
    return dayChList[parseInt(day)-1];
  }

  /**
   * @desc 按照時間打招呼
   * @return {str}
   */
  static sayhi() {
    const nowTime = moment(new Date());
    const time1 = moment('06:00' ,'HH:mm');
    const time2 = moment('11:00' ,'HH:mm');
    const time3 = moment('18:00' ,'HH:mm');
    
    if (nowTime.isAfter(time1) && nowTime.isBefore(time2)) return '早安'
    else if (nowTime.isAfter(time2) && nowTime.isBefore(time3)) return '午安'
    else if (nowTime.isAfter(time3) && nowTime.isBefore(time1)) return '晚安'
    return '嗨'
  }
}